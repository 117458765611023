* {
  margin: 0;
  padding: 0;
  line-height: 1.5;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

select:invalid { color: gray; }

/* .embedsocial-reviews-iframe {
  height: 400px !important;
}

@media only screen and (min-width: 768px) {
  .embedsocial-reviews-iframe{
      height: 400px !important;
    }
}

@media only screen and (min-width: 992px) {
  .embedsocial-reviews-iframe{
      height: 400px !important;
    }
}

@media only screen and (min-width: 1200px) {
  .embedsocial-reviews-iframe{
      height: 550px !important;
    }
} */